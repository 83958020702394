@import "../assets/scss/variables";

/* -------------------------------
   TIME COMPONENT
   --
   Mobile First !
------------------------------- */

.cbo-time {
  position: absolute;
  bottom: 3%;
  left: 50%;
  color: $colorwhite;
  font-size: 1.75rem;
  font-weight: 300;
  text-align: center;
  transform: translateX(-50%);
  z-index: 100;
  transition: all .5s;

  .time-pause {
    display: block;
    margin: 0 auto;
    background: none;
    border: none;
    color: $colorwhite;
    text-transform: uppercase;
    font-size: 0.75rem;
    letter-spacing: 0.2em;
    padding: 0;
    cursor: pointer;
  }
}

.cbo-game.game--tutorial .cbo-time{
  opacity:0.5;
}

/* -------------------------------
   SMALL SCREENS
------------------------------- */

@media (min-width: $screen-sm-min) {
  .cbo-time {
  }
}

/* -------------------------------
   MEDIUM SCREENS
------------------------------- */

@media (min-width: $screen-md-min) {
  .cbo-time {
    bottom: 5%;
    margin-bottom: -1.75rem;
    font-size: 4.75rem;

    .time-pause {
      font-size: 1.25rem;
    }
  }
}

/* -------------------------------
   LARGE SCREENS
------------------------------- */

@media (min-width: $screen-lg-min) {
  .cbo-time {
  }
}

/* -------------------------------
   EXTRA LARGE SCREENS
------------------------------- */

@media (min-width: $screen-xl-min) {
  .cbo-time {
  }
}
