//== Colors

$colorgreen: #40f178;
$colorred: #ff4412;
$colorgreydark: #1a1f2c;
$colorgreymedium: #3b4256;
$colorgrey: #97a0ba;
$colorwhite: #ffffff;

//== Easings

$easeinout: cubic-bezier(0.215, 0.6, 0.27, 0.995);
$easeinout2: cubic-bezier(0.85, 0, 0.15, 1);

//== Media queries breakpoints

// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 1024px !default;
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1280px !default;
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// XLarge screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-xl: 1600px !default;
$screen-xl-min: $screen-xl !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-xl-desktop: $screen-xl-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
$screen-lg-max: ($screen-xl-min - 1) !default;