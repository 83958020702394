@import "../assets/scss/variables";

/* -------------------------------
   SCORE COMPONENT
   --
   Mobile First !
------------------------------- */

.cbo-score {
  position: absolute;
  bottom: 5%;
  right: 5%;
  color: #1a1f2c;
  font-size: 1rem;
  font-weight: 300;
  z-index: 100;
  transition: opacity 0.5s;

  .score-background {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    svg {
      display: block;
      width: 100%;
      height: 100%;

      path {
        fill: white;
        transition: fill 0.5s;
      }
    }
  }

  .score-counter {
    display: block;
    position: relative;
    text-align: right;
    min-width: 6.25rem;
    padding: 0.25rem 1rem;
  }
}

.cbo-game.game--tutorial .cbo-score {
  opacity: 0.5;
}

/* -------------------------------
   SMALL SCREENS
------------------------------- */

@media (min-width: $screen-sm-min) {
  .cbo-score {
  }
}

/* -------------------------------
   MEDIUM SCREENS
------------------------------- */

@media (min-width: $screen-md-min) {
  .cbo-score {
    font-size: 3rem;

    .score-counter {
      min-width: 15.625rem;
      padding: 0.5rem 1.5rem;
    }
  }
}

/* -------------------------------
   LARGE SCREENS
------------------------------- */

@media (min-width: $screen-lg-min) {
  .cbo-score {
  }
}

/* -------------------------------
   EXTRA LARGE SCREENS
------------------------------- */

@media (min-width: $screen-xl-min) {
  .cbo-score {
  }
}
