@import "../assets/scss/variables";

/* -------------------------------
   POPIN COMPONENT
   --
   Mobile First !
------------------------------- */

.cbo-popin {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  max-width: 85%;
  max-height: 85%;
  border-radius: 1rem;
  transform: translateX(-50%) translateY(-50%);
  padding: 1.5rem 2rem;
  background: #1a1f2c;
  color: white;
  z-index: 5000;
  text-align: center;
  box-shadow: 0 0 2rem 1rem rgba(black, 0.2);
  overflow: auto;

  .popin-inner {
  }

  .popin-title {
    font-size: 2.25rem;
    line-height: 1em;
    font-weight: 300;
    margin: 0 0 0.5em 0;
  }

  .popin-content {
    flex-grow: 1;

    p {
      font-size: 0.9375rem;
      font-weight: 300;
      line-height: 1.35em;
    }
  }

  .popin-button {
    .cbo-button {
      display: flex;
      width: 100%;
      max-width: 20rem;
      margin: 0.5rem auto 0 auto;
    }
  }
}

/* -------------------------------
   SMALL SCREENS
------------------------------- */

@media (min-width: $screen-sm-min) {
  .cbo-popin {
  }
}

/* -------------------------------
   MEDIUM SCREENS
------------------------------- */

@media (min-width: $screen-md-min) {
  .cbo-popin {
    padding: 4rem 6rem;

    .popin-title {
      font-size: 4rem;
    }

    .popin-content {
      p {
        font-size:1.6250rem;
        line-height: 1.5em;
      }
    }

    .popin-button{
      .cbo-button {
        margin: 1rem 0 0 0;
      }
    }
  }
}

/* -------------------------------
   LARGE SCREENS
------------------------------- */

@media (min-width: $screen-lg-min) {
  .cbo-popin {
  }
}

/* -------------------------------
   EXTRA LARGE SCREENS
------------------------------- */

@media (min-width: $screen-xl-min) {
  .cbo-popin {
  }
}
