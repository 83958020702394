@keyframes water {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 0 -2%;
    transform: translateX(-2%);
  }
}

.cbo-water {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12;
  pointer-events: none;

  .water-fill {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    will-change: height;
    transition: height 0.5s ease-out;

    .fill {
      position: absolute;
      width: 300%;
      height: 100%;
      left: 0;
      top: 0;

      svg {
        content: "";
        display: block;
        position: absolute;
        width: 110vw;
        height: auto;
        top: 0;
        left: 0;

        path{
          transition: fill .5s;
        }
      }

      &.fill--a svg {
        animation: water ease-in-out infinite alternate-reverse 2s;
      }

      &.fill--b svg {
        animation: water ease-in-out infinite alternate-reverse 1.8s;
        animation-delay: 1s;
      }
    }
  }
}
