@import "../assets/scss/variables";

/* -------------------------------
   BUTTON COMPONENT
   --
   Mobile First !
------------------------------- */

.cbo-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 3.5rem;
  padding: 0;
  background: none;
  border: none;
  color: $colorwhite;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: normal;
  letter-spacing: 0.12em;
  position: relative;
  cursor: pointer;
  text-decoration: none;

  &[disabled]{
    cursor:initial;
  }

  .button-background {
    display: block;
    position: absolute;
    top: 15%;
    left: -7.5%;
    bottom: 15%;
    right: -7.5%;

    svg {
      display: block;
      width: 100%;
      height: 100%;

      path {
        transition: fill 0.3s;
      }
    }
  }

  .button-label {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 1rem;
    border: 3px solid $colorwhite;
  }

  &:active {
    .button-background {
      svg path {
        fill: $colorred;
      }
    }
  }

  &.button--red {
    .button-background {
      svg path {
        fill: $colorred;
      }
    }
  }

  &.button--white {
    .button-background {
      svg path {
        fill:$colorwhite;
      }
    }

    .button-label {
      color:$colorgreydark;
      border-color:$colorgreydark;
    }
  }

  &.button--transparent{
    color:$colorgrey;
    height:auto;
    transition: color 0.3s;

    .button-background{
      display: none;
    }

    .button-label{
      padding:0;
      border:0;
    }

    &:active{
      color: $colorred;
    }
  }
}

/* -------------------------------
   SMALL SCREENS
------------------------------- */

@media (min-width: $screen-sm-min) {
  .cbo-button {
  }
}

/* -------------------------------
   MEDIUM SCREENS
------------------------------- */

@media (min-width: $screen-md-min) {
  .cbo-button {
    height: 5.3125rem;
    font-size: 1.5625rem;

    .button-label {
      padding: 0 2rem;
    }

    &:hover {
      .button-background {
        svg path {
          fill: $colorred;
        }
      }
    }

    &.button--transparent{
      &:hover{
        color:$colorred;
      }
    }
  }
}

/* -------------------------------
   LARGE SCREENS
------------------------------- */

@media (min-width: $screen-lg-min) {
  .cbo-button {
  }
}

/* -------------------------------
   EXTRA LARGE SCREENS
------------------------------- */

@media (min-width: $screen-xl-min) {
  .cbo-button {
  }
}
