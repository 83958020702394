.cbo-game {
  position:fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: #1a1f2c;

  &:before{
    content:'';
    display: block;
    position:absolute;
    top:0; left:0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 6vw 2vw rgba(black, 0.75) inset;
    z-index: 2;
  }

  .game-controls{
    position:absolute;
    top:5%;
    left:5%;
    z-index: 100;
  }
}