@import "../assets/scss/variables";

/* -------------------------------
   LEADERBOARD COMPONENT
   --
   Mobile First !
------------------------------- */

.cbo-leaderboard {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $colorgreydark;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 6vw 2vw rgba(black, 0.75) inset;
  }

  .leaderboard-inner {
    width: 80%;
    max-width: 84.375rem;
  }

  .leaderboard-step {
    position: relative;
    color: $colorwhite;
    padding: 2rem 0;
    height: 100%;

    &.step--scores {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
    }

    h2 {
      font-size: 2.5rem;
      font-weight: 300;
      margin: 0 0 0.5em 0;
    }

    p {
      line-height: 1.5em;
      font-weight: 300;
    }

    .step-form {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -0.5rem;

      input[type="email"],
      input[type="text"] {
        width: 100%;
        height: 3.5rem;
        border: 3px solid $colorgreymedium;
        border-radius: 0.25rem;
        background: $colorgreydark;
        color: $colorgrey;
        padding: 0 1rem;
        font-size: 1rem;
        font-weight: 300;
        transition: all 0.3s;

        &::placeholder {
          color: $colorgrey;
          font-weight: 300;
        }
      }

      input[type="checkbox"] {
        position: absolute;
        width: 1px;
        height: 1px;
        opacity: 0.000001;

        & + label {
          display: block;
          position: relative;
          padding: 0 0 0 2rem;
          font-size: 0.875rem;
          font-weight: 300;
          line-height: 1.25em;

          &:before {
            content: "";
            display: block;
            width: 1.25rem;
            height: 1.25rem;
            border: 3px solid $colorgreymedium;
            border-radius: 0.25rem;
            position: absolute;
            top: 0;
            left: 0;
            transition: all 0.3s;
            cursor: pointer;
          }

          &:after {
            content: "";
            display: block;
            width: 0.75rem;
            height: 0.75rem;
            background: $colorgreen;
            border-radius: 0.125rem;
            position: absolute;
            top: 0.25rem;
            left: 0.25rem;
            transform: scale(0);
            transition: transform 0.3s $easeinout;
            cursor: pointer;
          }
        }

        &:checked + label {
          &:after {
            transform: scale(1);
          }
        }
      }

      .invalid {
        border-color: $colorred !important;

        & + label {
          &:before {
            border-color: $colorred !important;
          }
        }
      }

      .form-field {
        width: 50%;
        padding: 0.5rem;

        &.field--full {
          width: 100%;
        }

        &.field--rgpd {
          width: 70%;
        }
      }

      .form-submit {
        width: 30%;
        padding: 0.5rem;
        text-align: right;
        position:relative;

        button {
          width: 100%;
          position:relative;

          &:disabled{
            opacity:0;
            cursor: initial;
          }
        }

        .cbo-spinner{
          position:absolute;
          top:50%;
          right:0;
          transform:translateY(-50%);
          opacity:0;
          transition: opacity .3s;
        }

        &.loading{
          button{
            opacity: 0;
            cursor: initial;
          }

          .cbo-spinner{
            opacity: 1;
          }
        }
      }
    }

    .step-summary {
      width: 65%;

      .summary-score {
        p {
          font-size: 1.125rem;
          font-weight: 300;
          margin: 0.25rem 0;
        }

        .score-value {
          display: inline-block;
          font-size: 2rem;
          font-weight: 300;
          line-height: 1em;
          background: url(../assets/img/sketch.svg) no-repeat center;
          background-size: 100% 100%;
          padding: 0.25em 0.5em;
        }

        .score-position {
          display: inline-block;
          font-size: 1em;
          font-weight: 300;
          line-height: 1em;
          background: url(../assets/img/sketch.svg) no-repeat center;
          background-size: 100% 100%;
          padding: 0.25em 0.25em;
        }
      }

      .summary-bottom {
        border-top: 3px solid $colorgreymedium;
        margin-top: 1rem;
        padding-top: 1.25rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .bottom-share {
          display: none;
        }
      }
    }

    .step-scores {
      width: 30%;
      max-height: 75vh;
      overflow: auto;
      padding: 2rem 0 2rem 1rem;

      .scores-el,
      .scores-separator {
        font-size: 1.5rem;
        font-weight: 300;
        padding: 0.35rem 0;
        padding-left: 1rem;
        border-left: 3px solid $colorgreymedium;
        margin: 0;
        color: $colorgrey;
      }

      .scores-el {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .el-position {
          display: inline-block;
          min-width: 4rem;
          color: $colorgrey;
        }

        .el-score {
          color: $colorwhite;
        }

        &.el--current {
          position: relative;

          &:before {
            content: "";
            display: block;
            position: absolute;
            left: -2rem;
            right: 0;
            top: 0;
            bottom: 0;
            background: url(../assets/img/sketch.svg) no-repeat center;
            background-size: 100% 100%;
          }

          .el-position,
          .el-score {
            position: relative;
          }
        }
      }
    }
  }
}

/* -------------------------------
   SMALL SCREENS
------------------------------- */

@media (min-width: $screen-sm-min) {
  .cbo-leaderboard {
  }
}

/* -------------------------------
   MEDIUM SCREENS
------------------------------- */

@media (min-width: $screen-md-min) {
  .cbo-leaderboard {
    .leaderboard-step {
      padding: 15vh 0;

      h2 {
        font-size: 5.75rem;
        margin-bottom: 0.25em;
      }

      p {
        font-size: 2.25rem;
      }

      .step-form {
        margin: 0 -2rem;

        input[type="email"],
        input[type="text"] {
          height: 5.3125rem;
          font-size: 2.25rem;
          padding: 0 1.875rem;
          border-width: 4px;
        }

        input[type="checkbox"] {
          & + label {
            padding-left: 3rem;
            font-size: 1.25rem;

            &:before {
              width: 2rem;
              height: 2rem;
            }

            &:after {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }

        .form-field {
          padding: 1.125rem 2rem;

          &.field--rgpd {
            width: 100%;
          }
        }

        .form-submit {
          width: 100%;
          margin: 2rem 2rem 0 2rem;
          padding: 3.5rem 0 0 0;
          text-align: left;
          border-top: 4px solid $colorgreymedium;

          .cbo-button {
            width: auto;
          }

          .cbo-spinner {
            left:0;
            right:auto;
            margin-top:1.75rem;
          }
        }
      }

      .step-summary {
        .summary-score {
          p {
            font-size: 3rem;
          }

          .score-value {
            font-size: 5.75rem;
          }
        }

        .summary-bottom {
          margin-top: 3.4375rem;
          border-width: 4px;
          padding-top: 4rem;
        }
      }

      .step-scores {
        padding-left: 2rem;

        .scores-el,
        .scores-separator {
          padding: 0.5rem 0 0.5rem 1.5rem;
          border-width: 4px;
        }

        .scores-el {
          font-size: 2.25rem;

          .el-position {
            min-width: 7.1875rem;
          }
        }
      }
    }
  }
}

/* -------------------------------
   LARGE SCREENS
------------------------------- */

@media (min-width: $screen-lg-min) {
  .cbo-leaderboard {
  }
}

/* -------------------------------
   EXTRA LARGE SCREENS
------------------------------- */

@media (min-width: $screen-xl-min) {
  .cbo-leaderboard {
  }
}
