@import "./assets/scss/variables";
@import "./assets/scss/spinner";

/* -------------------------------
   GLOBALS
------------------------------- */

* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}

* {
  -webkit-tap-highlight-color: transparent;
}

body {
  background: #1a1f2c;
}

/* -------------------------------
   APP STYLES
   --
   Mobile First !
------------------------------- */

.cbo-app {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: $colorgreydark;

  .app-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;

    .header-logo {
      position: absolute;
      display: block;
      top: 1.5rem;
      left: 1.5rem;
      width: 2rem;
      text-decoration: none;
      background: none;
      border: none;
      padding: 0;
      transition: all .5s $easeinout2;

      svg {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .header-languages {
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      transition: all .5s $easeinout2 .1s;

      button {
        position: relative;
        height: 1.5rem;
        padding: 0.1rem 0.5rem 0 0.5rem;
        background: none;
        border: none;
        color: $colorwhite;
        font-size: 1rem;
        line-height: 1;
        margin: 0 0 0 0.5rem;
        cursor: pointer;

        svg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        span {
          position: relative;
        }

        &.active svg path {
          fill: $colorred;
        }
      }
    }
  }

  &.app--playing{
    .header-logo{
      left:0 !important;
      transform:translateX(-100%) !important;
    }

    .header-languages{
      right:0 !important;
      transform:translateX(100%) !important;
    }
  }
}

/* -------------------------------
   SMALL SCREENS
------------------------------- */

@media (min-width: $screen-sm-min) {
  .cbo-app {
  }
}

/* -------------------------------
   MEDIUM SCREENS
------------------------------- */

@media (min-width: $screen-md-min) {
  .cbo-app {
    .app-header {
      .header-logo {
        width: 2.75rem;
        top: 3.125rem;
        left: 3.125rem;
      }

      .header-languages {
        top: 3.125rem;
        right: 3.125rem;

        button {
          height: 2rem;
          font-size: 1.125rem;
          margin-left: 0.75rem;
        }
      }
    }
  }
}

/* -------------------------------
   LARGE SCREENS
------------------------------- */

@media (min-width: $screen-lg-min) {
  .cbo-app {
  }
}

/* -------------------------------
   EXTRA LARGE SCREENS
------------------------------- */

@media (min-width: $screen-xl-min) {
  .cbo-app {
  }
}
