@import '../assets/scss/variables';

@keyframes shaking{
  0%{
    transform:translate(0,-1.5%) rotate(-0.25deg);
  }
  50%{
    transform:translate(0,1.5%) rotate(0.25deg);
  }
}

@keyframes leaking{
  0%{
    transform:translateY(-25%);
    opacity:1;
  }
  100%{
    transform: translateY(0%);
    opacity:0;
  }
}

@keyframes leaking-end{
  0%{
    transform:translateY(-100%) scale(1);
    opacity: 1;
  }
  50%{
    transform:translateY(-50%) scale(1);
    opacity: .75;
  }
  100%{
    transform: translateY(-5%) scale(0.25);
    opacity: 0;
  }
}

.cbo-pipe {
  display: block;
  width:33%;
  max-width: 6rem;
  position:relative;
  z-index: 1;

  .pipe-button{
    display: block;
    width: 100%;
    height: auto;
    border: none;
    position:relative;
    cursor: pointer;
    padding:0;
    padding-bottom: 83.33%;
    background: url(../assets/img/connector.svg) no-repeat center;
    background-size: contain;
    user-select:none;
    z-index: 1;
    //transition:transform .3s $easeinout;
  }

  .pipe-leak{
    content:'';
    display: block;
    width: 43%;
    padding-bottom:102.0819%;
    position:absolute;
    top:90%;
    left:50%;
    transform: translateX(-50%) scaleY(0);
    transform-origin: top;
    transition: transform .5s ease-in-out;

    svg{
      g{
        display: block;
        animation: leaking linear infinite .5s;
        transform-origin: bottom;
        opacity: 0.8;

        &:nth-child(2){ animation-delay: .05s; }
        &:nth-child(3){ animation-delay: .1s; }
        &:nth-child(4){ animation-delay: .15s; }
        &:nth-child(5){ animation-delay: .2s; }
        &:nth-child(6){ animation-delay: .25s; }
        &:nth-child(7){ animation-delay: .3s; }
        &:nth-child(8){
          animation: leaking-end linear infinite .5s;
          animation-delay: .9s;
        }
        &:nth-child(9){
          animation: leaking-end linear infinite .5s;
          animation-delay: 1s;
        }
        &:nth-child(10){
          animation: leaking-end linear infinite .5s;
          animation-delay: 1.1s;
        }
      }
    }
  }

  &.pipe--leak{
    .pipe-button{
      animation: shaking linear infinite .25s;
    }

    .pipe-leak{
      transform: translateX(-50%) scaleY(1);
    }
  }

  &.pipe--active{
    .pipe-button{
      transform: scale(1.1);
      transition-delay: 0s;
    }
  }
}
