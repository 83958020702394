@import "../assets/scss/variables";

/* -------------------------------
   TUTORIAL COMPONENT
   --
   Mobile First !
------------------------------- */

.cbo-tutorial {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  .tutorial-box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 2rem;
    width: 40%;
    min-height: 60vh;
    transform: translateY(-50%);
    border: 2px solid $colorgreen;
    border-radius: 1rem;
    background: $colorgreydark;
    color: $colorwhite;
    padding: 1.5rem;
    box-shadow: 0 0 2rem 1rem rgba(black, 0.2);

    .box-content p {
      margin-top: 0;
      font-size: 1.125rem;
      font-weight: 300;
      line-height: 1.35em;
    }

    .box-buttons div {
      margin-top: 1rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}

.cbo-game.game--step1 {
  .cbo-grid {
    .grid-cel:nth-child(7) {
      .cel-content {
        position: relative;
        opacity: 1;

        .cbo-pipe {
          &:before,
          &:after {
            content: "";
            display: block;
            width: 300%;
            padding-bottom: 300%;
            border-radius: 999rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          &:before {
            border: 2px solid $colorgreen;
            z-index: 11;
          }

          &:after {
            background: $colorgreen;
            mix-blend-mode: color;
            opacity: 0.125;
            z-index: 10;
          }
        }
      }
    }
  }
}

.cbo-game.game--step2 {
  .cbo-tutorial {
    .tutorial-box {
      border-color: $colorred;
    }
  }

  .cbo-grid {
    .grid-cel:nth-child(3) {
      .cel-content {
        position: relative;
        opacity: 1;

        .cbo-pipe {
          &:before,
          &:after {
            content: "";
            display: block;
            width: 300%;
            padding-bottom: 300%;
            border-radius: 999rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          &:before {
            border: 2px solid $colorred;
            z-index: 11;
          }

          &:after {
            background: $colorred;
            mix-blend-mode: color;
            opacity: 0.125;
            z-index: 10;
          }
        }
      }
    }
  }
}

.cbo-game.game--step3 {
  .cbo-time {
    color: $colorgreen;
    opacity: 1;
  }

  .cbo-score {
    opacity: 1;

    .score-background svg path {
      fill: $colorgreen;
    }
  }
}

.cbo-game.game--step4 {
  .cbo-water {
    .water-fill .fill svg path {
      fill: $colorgreen;
    }
  }
}

/* -------------------------------
   SMALL SCREENS
------------------------------- */

@media (min-width: $screen-sm-min) {
  .cbo-tutorial {
  }
}

/* -------------------------------
   MEDIUM SCREENS
------------------------------- */

@media (min-width: $screen-md-min) {
  .cbo-tutorial {
    .tutorial-box {
      width: 35rem;
      min-height: 40rem;
      padding: 4rem 3.375rem 3.375rem 3.375rem;
      left: 4.5rem;
      border-width: 3px;

      .box-content p {
        font-size: 2.25rem;
      }
    }
  }
}

/* -------------------------------
   LARGE SCREENS
------------------------------- */

@media (min-width: $screen-lg-min) {
  .cbo-tutorial {
  }
}

/* -------------------------------
   EXTRA LARGE SCREENS
------------------------------- */

@media (min-width: $screen-xl-min) {
  .cbo-tutorial {
  }
}
