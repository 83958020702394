@import "../assets/scss/variables";

/* -------------------------------
   TIME COMPONENT
   --
   Mobile First !
------------------------------- */

.cbo-rotate {
  position: absolute;
  width: 100%;
  height: 100%;
  background: $colorgreydark;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  transform: translateY(-100%);
  opacity: 0;
  transition: opacity .1s, transform .01s linear .1s;

  .rotate-inner{
    width: 80%;
    max-width: 30rem;
    margin:0 auto;
  }

  .rotate-text {
    margin:0 auto;

    p {
      color: $colorwhite;
      font-size: 1.25rem;
      font-weight: 300;
      line-height: 1.25em;
      text-align: center;
    }
  }

  .rotate-picture {
    margin:3rem auto 0 auto;
    width: 10rem;

    svg path {
      fill: $colorwhite;
    }
  }
}

@media (orientation: 'portrait') {
  .cbo-rotate{
    opacity: 1;
    transform: translateY(0);
    transition: opacity .1s linear .01s, transform .01s;
  }
}

/* -------------------------------
   SMALL SCREENS
------------------------------- */

@media (min-width: $screen-sm-min) {
  .cbo-rotate {
  }
}

/* -------------------------------
   MEDIUM SCREENS
------------------------------- */

@media (min-width: $screen-md-min) {
  .cbo-rotate {
  }
}

/* -------------------------------
   LARGE SCREENS
------------------------------- */

@media (min-width: $screen-lg-min) {
  .cbo-rotate {
  }
}

/* -------------------------------
   EXTRA LARGE SCREENS
------------------------------- */

@media (min-width: $screen-xl-min) {
  .cbo-rotate {
  }
}
