@import "../assets/scss/variables";

/* -------------------------------
   HOME COMPONENT
   --
   Mobile First !
------------------------------- */

.cbo-home {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1a1f2c;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 6vw 2vw rgba(black, 0.75) inset;
    z-index: 2;
  }

  .home-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: scale(1.1);
    transition: all 0.5s $easeinout2 0.5s;

    .background-topleft {
      position: absolute;
      top: 0;
      left: 0;
      width: 30vw;
      height: 20vh;

      &:before,
      &:after {
        content: "";
        display: block;
        position: absolute;
      }

      &:before {
        bottom: 0;
        left: 0;
        right: 1px;
        height: 2vw;
        min-height: 2rem;
        background: url(../assets/img/pipe-h.svg) repeat-x center;
        background-size: auto 100%;
      }

      &:after {
        top: -40vh;
        bottom: 0;
        right: 0;
        width: 2vw;
        min-width: 2rem;
        background: url(../assets/img/pipe-v.svg) repeat-y center;
        background-size: 100% auto;
        transform: skewY(45deg);
        transform-origin: bottom right;
      }
    }

    .background-right {
      position: absolute;
      top: 0;
      right: 0;
      width: 10vw;
      height: 100vh;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 2vw;
        min-width: 2rem;
        background: url(../assets/img/pipe-v.svg) repeat-y center;
        background-size: 100% auto;
      }

      .cbo-pipe {
        width: 5.5vw;
        min-width: 5.5rem;
        max-width: none;
        transform: rotate(90deg) translateY(38%);
        position: absolute;
        left: 0;
        top: 20%;
      }
    }

    .background-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 20vh;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 1px;
        height: 2vw;
        min-height: 2rem;
        background: url(../assets/img/pipe-h.svg) repeat-x center;
        background-size: auto 100%;
      }

      .cbo-pipe {
        width: 5.5vw;
        min-width: 5.5rem;
        max-width: none;
        transform: rotate(-180deg) translateY(27%);
        position: absolute;
        left: 10%;
        top: 0;
      }
    }
  }

  .home-inner {
    width: 100%;
    position: relative;
    z-index: 10;
  }

  .home-logo {
    width: 20%;
    max-width: 23.75rem;
    margin: 0 auto 10vh auto;
    opacity: 0;
    transform: scale(1.1);
    transition: all 0.5s $easeinout2 0.8s;

    svg {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .home-buttons{
    opacity: 0;
    transform: scale(1.1);
    transition: all 0.5s $easeinout2 1s;
  }

  .home-button {
    text-align: center;
    opacity: 0;
    position: relative;
    transform: scale(0.9);
    transition: all 0.5s $easeinout;

    &.button--active {
      opacity: 1;
      transform: scale(1);
    }
  }

  .home-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 3.5rem;
    opacity: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.5s linear;

    .loading-title {
      text-align: center;
      color: $colorwhite;
      font-size: 0.75rem;
      margin: 0 0 0.25rem 0;
    }

    .loading-bar {
      width: 18.75rem;
      height: 0.25rem;
      border-radius: 999rem;
      position: relative;
      overflow: hidden;

      div {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        background: $colorwhite;
        transition: width 0.15s $easeinout;
      }
    }

    &.loading--active {
      opacity: 1;
    }
  }

  .home-fullscreen {
    text-align: center;
    margin-top: 1rem;

    button {
      display: block;
      margin: 0 auto;
      background: none;
      border: none;
      color: white;
      text-transform: uppercase;
      font-size: 0.75rem;
      letter-spacing: 0.2em;
      padding: 0;
      cursor: pointer;
    }
  }

  &.home--active {
    .home-background,
    .home-logo,
    .home-buttons {
      opacity: 1;
      transform: scale(1);
    }
  }
}

/* -------------------------------
   SMALL SCREENS
------------------------------- */

@media (min-width: $screen-sm-min) {
  .cbo-home {
  }
}

/* -------------------------------
   MEDIUM SCREENS
------------------------------- */

@media (min-width: $screen-md-min) {
  .cbo-home {
    .home-loading {
      height: 5.3125rem;
    }

    .home-fullscreen {
      margin-top: 1rem;
    }
  }
}

/* -------------------------------
   LARGE SCREENS
------------------------------- */

@media (min-width: $screen-lg-min) {
  .cbo-home {
  }
}

/* -------------------------------
   EXTRA LARGE SCREENS
------------------------------- */

@media (min-width: $screen-xl-min) {
  .cbo-home {
  }
}
