.cbo-grid {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  .grid-cel {
    position: relative;
    //border: 1px solid rgba(white, 0.05);

    .cel-pipe {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      &.pipe--h {
        width: 100%;
        max-height: 2.25rem;
        background: url(../assets/img/pipe-h.svg) repeat-x center;
        background-size: auto 100%;
        box-shadow: 0 -0.5rem 0.5rem -0.5rem rgba(black, 0.15);

        .pipe-inner {
          width: 100%;
          padding-bottom: 10%;
        }
      }

      &.pipe--v {
        width: 10%;
        height: 100%;
        max-width: 2.25rem;
        background: url(../assets/img/pipe-v.svg) repeat-y center;
        background-size: 100% auto;
        box-shadow: -0.5rem 0 0.5rem -0.5rem rgba(black, 0.15);
      }
    }

    .cel-content {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
    }

    .cbo-pipe {
      margin-right: auto;
      margin-left: 0;
    }

    &:nth-child(3n + 1) {
      .cbo-pipe {
        margin-right: 0;
        margin-left: auto;
        transform: scaleY(-1);
      }
    }

    &:nth-child(4n + 1) {
      &:not(.cel--r0) {
        .cbo-pipe {
          margin-right: auto;
          margin-left: auto;
          margin-top: -50%;
          transform: rotate(90deg);
        }
      }

      &.cel--r1 .cbo-pipe,
      &.cel--r3 .cbo-pipe {
        transform: rotate(-90deg);
      }
    }

    &:nth-child(2n + 1) {
      .cel-pipe {
        &.pipe--h {
          z-index: 1;
        }
      }
    }
  }

  &.grid--fake {
    top: 80%;
  }
}

.cbo-game.game--tutorial .cbo-grid {
  .grid-cel {
    .cel-pipe {
      opacity: 0.33;
    }

    .cel-content {
      opacity: 0.5;
    }
  }
}
