@import '../assets/scss/variables';

/* -------------------------------
   LEVEL COMPONENT
   --
   Mobile First !
------------------------------- */

.cbo-level {
  position: absolute;
  bottom: 5%;
  left: 5%;
  color: #1a1f2c;
  font-size: 1rem;
  font-weight: 300;
  z-index: 100;

  .level-background {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    svg {
      display: block;
      width: 100%;
      height: 100%;

      path {
        fill: #40f178;
      }
    }
  }

  .level-label {
    display: block;
    position: relative;
    text-align: right;
    padding:0.25rem 1rem;
  }
}

/* -------------------------------
   SMALL SCREENS
------------------------------- */

@media (min-width: $screen-sm-min) {
  .cbo-level {
  }
}

/* -------------------------------
   MEDIUM SCREENS
------------------------------- */

@media (min-width: $screen-md-min) {
  .cbo-level {
    font-size: 3rem;

    .level-label {
      padding:0.5rem 1.5rem;
    }
  }
}

/* -------------------------------
   LARGE SCREENS
------------------------------- */

@media (min-width: $screen-lg-min) {
  .cbo-level {
  }
}

/* -------------------------------
   EXTRA LARGE SCREENS
------------------------------- */

@media (min-width: $screen-xl-min) {
  .cbo-level {
  }
}
