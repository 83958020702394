@import '../assets/scss/variables';

/* -------------------------------
   POPIN COMPONENT
   --
   Mobile First !
------------------------------- */

.cbo-help {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  min-width:25rem;

  .help-picture{
    width:35%;

    h3{
      font-size:1rem;
      font-weight: 500;
      margin-top:0;
    }

    svg{
      display: block;
      width: 5rem;
      height:auto;
      margin:0 auto;
    }
  }

  .help-content{
    width:60%;
    text-align: left;

    p{
      margin-top:0;

      &:last-child{
        margin-bottom: 0;
      }
    }
  }

  .help-buttons{
    width:100%;
    text-align: center;
    margin-top:1rem;

    .cbo-button{
      margin:0.5rem 1rem;
    }
  }
}

/* -------------------------------
   SMALL SCREENS
------------------------------- */

@media (min-width: $screen-sm-min) {
  .cbo-help {
  }
}

/* -------------------------------
   MEDIUM SCREENS
------------------------------- */

@media (min-width: $screen-md-min) {
  .cbo-help {
    padding-top: 1.5rem;
    min-width:55rem;

    .help-picture {
      width: 42%;

      h3{
        font-size:2.2500rem;
      }

      svg {
        width:10.2500rem;
      }
    }

    .help-content {
      width: 45%;
    }

    .help-buttons{
      margin-top:6.8750rem;

      .cbo-button{
        margin:1rem 1.5rem;
      }
    }
  }
}

/* -------------------------------
   LARGE SCREENS
------------------------------- */

@media (min-width: $screen-lg-min) {
  .cbo-help {
  }
}

/* -------------------------------
   EXTRA LARGE SCREENS
------------------------------- */

@media (min-width: $screen-xl-min) {
  .cbo-help {
  }
}
