@import '../assets/scss/variables';

/* -------------------------------
   COUNTDOWN COMPONENT
   --
   Mobile First !
------------------------------- */

.cbo-countdown {
  position:absolute;
  top:0; left:0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  padding:2rem;
  background:rgba(#1a1f2c, 0.8);
  color:white;
  font-size:1.5rem;
  line-height: 2em;
  font-weight:400;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition:opacity .3s, transform .01s $easeinout .3s;

  strong{
    font-weight:400;
    font-size:1.9167em;
  }

  .countdown-separator{
    display: block;
    margin-bottom: 0.5em;
  }

  &.countdown--hidden{
    opacity:0;
    transform: translateY(-100%);
  }
}

/* -------------------------------
   SMALL SCREENS
------------------------------- */

@media (min-width: $screen-sm-min) {
  .cbo-countdown {
  }
}

/* -------------------------------
   MEDIUM SCREENS
------------------------------- */

@media (min-width: $screen-md-min) {
  .cbo-countdown {
    font-size: 3.0000rem;
  }
}

/* -------------------------------
   LARGE SCREENS
------------------------------- */

@media (min-width: $screen-lg-min) {
  .cbo-countdown {
  }
}

/* -------------------------------
   EXTRA LARGE SCREENS
------------------------------- */

@media (min-width: $screen-xl-min) {
  .cbo-countdown {
  }
}
