@import "../assets/scss/variables";

/* -------------------------------
   GAME OVER COMPONENT
   --
   Mobile First !
------------------------------- */

.cbo-gameover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  padding: 2rem;
  background:rgba(#1a1f2c, 0.8);
  color: white;
  font-size: 1.5rem;
  line-height: 2em;
  font-weight: 400;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s, transform 0.01s $easeinout 0.3s;

  .gameover-title {
    position: relative;
    font-weight: 300;
    margin-top: 0;
    color:$colorgreydark;

    svg {
      position: absolute;
      width: 120%;
      height: 140%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      path {
        fill: $colorwhite;
      }
    }

    span {
      display: inline-block;
      position: relative;
    }
  }

  .gameover-help {
    margin:2rem 0;

    .cbo-button {
      .button-background{
        top:5%;
        bottom:5%;
      }

      .button-label{
        text-transform: none;
        letter-spacing: 0.05em;
        padding-top:3rem;
        padding-bottom:3rem;
        line-height: 1em;
      }
    }
  }

  .gameover-buttons {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 1rem;
    min-width: 23rem;

    .cbo-button {
      width: 45%;
      color: $colorwhite;

      .button-label {
        text-align: left;
        align-items: flex-end;
        justify-content: flex-start;
        white-space: nowrap;
        border-bottom: 3px solid $colorwhite;
        padding-bottom: 0.5em;
        font-weight: 500;
      }
    }
  }
}

/* -------------------------------
   SMALL SCREENS
------------------------------- */

@media (min-width: $screen-sm-min) {
  .cbo-gameover {
  }
}

/* -------------------------------
   MEDIUM SCREENS
------------------------------- */

@media (min-width: $screen-md-min) {
  .cbo-gameover {
    font-size: 3rem;

    .gameover-title {
      margin-bottom: 5rem;
    }

    .gameover-buttons {
      margin-top: 3rem;
      min-width: 44rem;

      .cbo-button:hover {
        color:$colorred;
      }
    }
  }
}

/* -------------------------------
   LARGE SCREENS
------------------------------- */

@media (min-width: $screen-lg-min) {
  .cbo-gameover {
  }
}

/* -------------------------------
   EXTRA LARGE SCREENS
------------------------------- */

@media (min-width: $screen-xl-min) {
  .cbo-gameover {
  }
}
