@import "../assets/scss/variables";

/* -------------------------------
   PAUSE COMPONENT
   --
   Mobile First !
------------------------------- */

.cbo-pause {
  .cbo-button {
    margin-top: 1.5rem;
  }
}

/* -------------------------------
   SMALL SCREENS
------------------------------- */

@media (min-width: $screen-sm-min) {
  .cbo-pause {
  }
}

/* -------------------------------
   MEDIUM SCREENS
------------------------------- */

@media (min-width: $screen-md-min) {
  .cbo-pause {
    .cbo-button {
      margin-top: 2.5rem;
    }
  }
}

/* -------------------------------
   LARGE SCREENS
------------------------------- */

@media (min-width: $screen-lg-min) {
  .cbo-pause {
  }
}

/* -------------------------------
   EXTRA LARGE SCREENS
------------------------------- */

@media (min-width: $screen-xl-min) {
  .cbo-pause {
  }
}
